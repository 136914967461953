<template>
    <div class="deposit-offexchange">
        <div class="wrap-content">
            <div class="title-page">Deposit</div>
            <div class="nav-tab">
                <span class="tab" :class="{ activeTab: typePage == 'deposit' }" @click="filterTypeDeposit('deposit')">List Deposit</span>
                <span class="tab" :class="{ activeTab: typePage == 'metal' }" @click="filterTypeDeposit('metal')">List Metal</span>
            </div>
            <list-deposit-off
                v-if="typePage == 'deposit'"
                :depositDatas="getDataDeposit"
                :totalDeposit="getTotalData"
                :pageNumber="currentPage"
                :totalPerPage="perPage"
                :isLoadingData="isLoading"
            ></list-deposit-off>
            <list-metal-off
                v-else
                :depositDatas="getDataDeposit"
                :totalDeposit="getTotalData"
                :pageNumber="currentPage"
                :totalPerPage="perPage"
                :isLoadingData="isLoading"
            ></list-metal-off>

            <div class="d-flex justify-content-end mt-3">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="getTotalData"
                    :per-page="perPage"
                    @change="handlePageChange"
                    aria-controls="new-table"
                    first-number
                    last-number
                    v-show="getTotalData > 10"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import ListDepositOff from "./ListDepositOff.vue";
import ListMetalOff from "./ListMetalOff.vue";
export default {
    components: { ListDepositOff, ListMetalOff },
    data() {
        return {
            typePage: "deposit",
            today: "",
            currentPage: 1,
            perPage: 10,
            typeDeposit: "offexchange",
            contract_id: "",
            owner: "",
            hallmark: "",
            activity: "deposit",
            startDate: "",
            endDate: "",
            serialNumber: "",
            status: "",
            dateType: "",
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        this.getData(
            this.activity,
            this.perPage,
            this.currentPage,
            this.startDate,
            this.endDate,
            this.typeDeposit,
            this.owner,
            this.serialNumber,
            this.hallmark,
            this.dateType,
            this.status,
            this.contract_id
        );
    },
    computed: {
        getDataDeposit() {
            return this.$store.state.bullionModule.dataShow;
        },
        getTotalData() {
            return this.$store.state.bullionModule.lengthData;
        },
        isLoading() {
            return this.$store.state.bullionModule.isloading;
        },
        getDataContract() {
            return this.$store.state.bullionModule.contractData;
        },
        getDataClient() {
            return this.$store.state.bullionModule.clientData;
        },
    },
    methods: {
        filterTypeDeposit(event) {
            this.typePage = event;
            this.getData(
                this.activity,
                this.perPage,
                this.currentPage,
                this.startDate,
                this.endDate,
                this.typeDeposit,
                this.owner,
                this.serialNumber,
                this.hallmark,
                this.dateType,
                this.status,
                this.contract_id
            );
        },
        handlePageChange(event) {
            this.currentPage = event;
            this.getData(
                this.activity,
                this.perPage,
                this.currentPage,
                this.startDate,
                this.endDate,
                this.typeDeposit,
                this.owner,
                this.serialNumber,
                this.hallmark,
                this.dateType,
                this.status,
                this.contract_id
            );
        },
        getData(activity, perPage, currentPage, startDate, endDate, type, owner, serialNumber, hallmark, dateType, status, contract_id) {
            if (this.typePage == "deposit") {
                this.$store.dispatch("getDepositList", {
                    serialNumber: serialNumber,
                    perPage: perPage,
                    currentPage: currentPage,
                    owner: owner,
                    startDate: startDate,
                    endDate: endDate,
                    status: status,
                    type: type,
                });
            } else {
                this.$store.dispatch("getListMetal", {
                    activity: activity,
                    perPage: perPage,
                    currentPage: currentPage,
                    startDate: startDate,
                    endDate: endDate,
                    type: type,
                    owner: owner,
                    serialNumber: serialNumber,
                    hallmark: hallmark,
                    dateType: dateType,
                    status: status,
                    contract_id: contract_id,
                });
            }
        },
    },
};
</script>

<style scoped>
.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.nav-tab {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #636366;
    padding: 0.8rem 0;
    justify-self: flex-start;
}

.tab {
    padding: 0.8rem 1rem;
    cursor: pointer;
}

.tab:hover,
.activeTab {
    color: var(--scondary-color);
    border-bottom: 2px solid var(--scondary-color);
}
</style>
