<template>
    <div class="wrap-deposit">
        <div class="text-right mb-3">
            <button type="button" class="btn btn-info btn-sm" @click="isOpenFilter = true">Filter</button>
        </div>
        <div class="wrap-filter" v-if="isOpenFilter">
            <div class="filter">
                <div class="filter__date range">
                    <div class="form-group">
                        <label for="">Deposit Date</label>
                        <input type="month" :max="today" class="form-input" v-model="form.deposit_date" />
                    </div>
                </div>
                <div class="filter__hallmark">
                    <div class="form-group">
                        <label for="">Transaction ID</label>
                        <input type="text" placeholder="transaction id" class="form-input" v-model="form.transaction_id" />
                    </div>
                </div>
                <div class="filter__status">
                    <div class="form-group">
                        <label for="">Status</label>
                        <dropdown-directive>
                            <template v-slot:buttonDropdown>Input Status</template>
                            <template v-slot:menuDropdown>
                                <ul class="menu-dropdown" ref="open-dropdown">
                                    <li v-for="(status, i) in dataStatus" :key="i">
                                        <input type="checkbox" name="" id="" :value="status.id" v-model="form.statusMetal" />
                                        <label for="">{{ status.label }}</label>
                                    </li>
                                </ul>
                            </template>
                        </dropdown-directive>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-sm mr-3" @click="resetFilter">Reset</button>
                <button type="button" class="btn btn-success btn-sm" @click="selectFilter">Apply</button>
            </div>
        </div>
        <div class="view-perpages">
            <select v-model="perPage" @change="showDataRow">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            <span>&nbsp;entries per page</span>
        </div>
        <table>
            <thead>
                <tr>
                    <th>No</th>
                    <th>Transaction Id</th>
                    <th>Owner</th>
                    <th>Deposit Date</th>
                    <th>Deposit Due Date</th>
                    <th>Total Weight</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody v-if="isLoadingData">
                <tr>
                    <td colspan="7">
                        <b-spinner></b-spinner>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(deposit, i) in depositDatas" :key="i">
                    <td>{{ (pageNumber - 1) * totalPerPage + (i + 1) }}</td>
                    <td>{{ deposit.transaction_id }}</td>
                    <td>{{ deposit.owner }}</td>
                    <td>{{ deposit.deposit_date | formatDate }}</td>
                    <td>{{ deposit.due_date | formatDate }}</td>
                    <td>{{ deposit.deposit_detail ? deposit.deposit_detail.actual_total_weight : "" }}</td>
                    <td>
                        <span v-show="deposit.status == '000'" class="vendor">?</span>
                        <span v-show="deposit.status == '001'" class="vendor">New Submission</span>
                        <span v-show="deposit.status == '002'" class="abx">Approved By KMI</span>
                        <span v-show="deposit.status == '003'" class="operator">Waiting for Deposit</span>
                        <span v-show="deposit.status == '004'" class="clearing">Waiting for Test Result</span>
                        <span v-show="deposit.status == '005'" class="stored">
                            <span v-if="deposit.payment_info.payment_status == 'unpaid'">Waiting for Payment</span>
                            <span v-else>Stored</span>
                        </span>
                        <span v-show="deposit.status == '006'" class="reject">Deposit Suspended</span>
                        <span v-show="deposit.status == '007'" class="stored">Waiting for Re-Deposit Confirmation</span>
                        <span v-show="deposit.status == '008'" class="stored">Waiting for KMI Confirmation</span>
                        <span v-show="deposit.status == '009'" class="stored">Reject</span>
                    </td>
                    <td>
                        <router-link :to="'/off-exchange/deposit/' + deposit._id">
                            <b-button variant="outline-info" size="sm" class="m-1">Detail</b-button>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import DropdownDirective from "../dropdown/DropdownDirective.vue";
export default {
    components: { DropdownDirective },
    props: {
        depositDatas: Array,
        pageNumber: Number,
        totalPerPage: Number,
        totalDeposit: Number,
        isLoadingData: Boolean,
    },

    data() {
        return {
            perPage: this.totalPerPage,
            isOpenDropdown: false,
            isOpenFilter: false,
            dataStatus: [
                { id: "001", label: "New Submission" },
                { id: "002", label: "Approved By KMI" },
                { id: "003", label: "Waiting for Deposit" },
                { id: "004", label: "Waiting for Test Result" },
                { id: "005", label: "Stored" },
                { id: "006", label: "Deposit Suspended" },
                { id: "007", label: "Waiting for Re-Deposit Confirmation" },
                { id: "008", label: "Waiting for KMI Confirmation" },
            ],
            today: "",
            month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            form: {
                deposit_date: "",
                transaction_id: "",
                statusMetal: [],
                startDate: "",
                endDate: "",
            },
        };
    },

    methods: {
        openDropdown() {
            this.isOpenDropdown = !this.isOpenDropdown;
        },
        resetFilter() {
            this.isOpenFilter = false;
            this.form = {
                deposit_date: "",
                transaction_id: "",
                statusMetal: "",
                startDate: "",
                endDate: "",
            };
            this.$emit("setDataFilter", this.form);
        },
        selectFilter() {
            const date = new Date(this.form.deposit_date);
            const month = date.getMonth();
            const year = date.getFullYear();
            const maxDate = new Date(year, month + 1, 0).getDate();
            if (this.form.deposit_date != "") {
                this.form.startDate = this.form.deposit_date + "-01";
                this.form.endDate = this.form.deposit_date + "-" + JSON.stringify(maxDate);
            } else {
                this.form.startDate = "";
                this.form.endDate = "";
            }
            this.$emit("setDataFilter", this.form);
        },
        showDataRow() {
            this.$emit("changeRow", this.perPage);
        },
    },
};
</script>

<style scoped>
.wrap-filter {
    font-size: 13px;
    margin-bottom: 2rem;
}
.filter {
    display: flex;
    gap: 1.5rem;
}

.form-group label {
    display: block;
}

.menu-dropdown li {
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
}

.menu-dropdown {
    padding: 0.5rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 16%), 0 2px 4px rgb(0 0 0 / 8%);
    border-radius: 5px;
}

.form-input {
    border: solid 1px #ccc;
    height: 31px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #aaa;
    font-size: 12px;
    margin-right: 10px;
}
</style>
