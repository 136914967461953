<template>
    <div class="wrap-metal">
        <table>
            <thead>
                <tr>
                    <th>No</th>
                    <th>Serial Number</th>
                    <th>Fineness</th>
                    <th>Hallmark</th>
                    <th>Weight(gr)</th>
                    <th>Metal Type</th>
                    <th>Tanggal Cetak</th>
                    <th>Tahun</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody v-if="isLoadingData">
                <tr>
                    <td colspan="7">
                        <b-spinner></b-spinner>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(metal, i) in depositDatas" :key="i">
                    <td>{{ (pageNumber - 1) * totalPerPage + (i + 1) }}</td>
                    <td>{{ metal.serial_number }}</td>
                    <td>{{ metal.fineness }}</td>
                    <td>{{ metal.hallmark }}</td>
                    <td>{{ metal.weight }}</td>
                    <td>{{ metal.metal.type }}</td>
                    <td>{{ metal.metal_date | formatDate }}</td>
                    <td>{{ metal.year }}</td>
                    <td>
                        <span v-show="metal.status == '001'" class="vendor">Submited</span>
                        <span v-show="metal.status == '002'" class="abx">Approved</span>
                        <span v-show="metal.status == '003'" class="operator">Rejected</span>
                        <span v-show="metal.status == '004'" class="clearing">Stored</span>
                        <span v-show="metal.status == '005'" class="stored">Process</span>
                        <span v-show="metal.status == '006'" class="reject">Withdrawn</span>
                        <span v-show="metal.status == '007'" class="stored">Unsued</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        depositDatas: Array,
        pageNumber: Number,
        totalPerPage: Number,
        totalDeposit: Number,
        isLoadingData: Boolean,
    },
};
</script>

<style></style>
