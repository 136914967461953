<template>
    <div class="wrap-dropdown">
        <div type="button" ref="button" id="button" @click="showPopup = !showPopup"><slot name="buttonDropdown"></slot></div>
        <div
            class="dropdown"
            v-show="showPopup"
            :style="positionCss"
            v-closable="{
                exclude: ['button'],
                handler: 'onClose',
            }"
        >
            <slot name="menuDropdown"> Dropdown Opened </slot>
        </div>
    </div>
</template>

<script>
let handleOutsideClick;
import Vue from "vue";
Vue.directive("closable", {
    bind(el, binding, vnode) {
        handleOutsideClick = (event) => {
            event.stopPropagation();
            const { handler, exclude } = binding.value;
            let clickedOnExcludedEl = false;
            exclude.forEach((refName) => {
                if (!clickedOnExcludedEl) {
                    const excludeEl = vnode.context.$refs[refName];
                    if (excludeEl) {
                        clickedOnExcludedEl = excludeEl.contains(event.target);
                    }
                }
            });
            if (!el.contains(event.target) && !clickedOnExcludedEl) {
                vnode.context[handler]();
            }
        };
        document.addEventListener("click", handleOutsideClick);
        document.addEventListener("touchstart", handleOutsideClick);
    },
    unbind() {
        document.removeEventListener("click", handleOutsideClick);
        document.removeEventListener("touchstart", handleOutsideClick);
    },
});
export default {
    // directives: {
    //   closable: {
    //     bind(el, binding, vnode) {
    //       handleOutsideClick = (event) => {
    //         event.stopPropagation();
    //         const { handler, exclude } = binding.value;
    //         let clickedOnExcludedEl = false;
    //         exclude.forEach((refName) => {
    //           if (!clickedOnExcludedEl) {
    //             const excludeEl = vnode.ctx.ctx.$refs[refName];
    //             if (excludeEl) {
    //               clickedOnExcludedEl = excludeEl.contains(event.target);
    //             }
    //           }
    //         });
    //         if (!el.contains(event.target) && !clickedOnExcludedEl) {
    //           vnode.ctx.ctx[handler]();
    //         }
    //       };
    //       document.addEventListener("click", handleOutsideClick);
    //       document.addEventListener("touchstart", handleOutsideClick);
    //     },
    //     unbind() {
    //       document.removeEventListener("click", handleOutsideClick);
    //       document.removeEventListener("touchstart", handleOutsideClick);
    //     },
    //   },
    // },
    props: {
        position: {
            type: String,
            default: "right",
        },
        top: {
            type: String,
            default: "2rem",
        },
        right: {
            type: String,
            default: "0",
        },
        left: {
            type: String,
            default: "0",
        },
        width: {
            type: String,
            default: "10rem",
        },
        tryProps: String,
    },
    data() {
        return {
            showPopup: false,
        };
    },

    computed: {
        positionCss() {
            let style = "";
            if (this.position == "right") {
                style = {
                    right: this.right,
                    top: this.top,
                    minWidth: this.width,
                };
            } else if (this.position == "left") {
                style = {
                    left: this.left,
                    top: this.top,
                    minWidth: this.width,
                };
            }
            return style;
        },
    },

    methods: {
        onClose() {
            this.showPopup = false;
        },
    },
};
</script>

<style scoped>
.wrap-dropdown {
    position: relative;
}

.dropdown {
    background: #ffffff;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: left;
    position: absolute;
    /* min-width: 10rem; */
    z-index: 9;
}
</style>
